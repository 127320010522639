import "@rails/activestorage";
import "@hotwired/turbo-rails";
import { Application } from "@hotwired/stimulus";
import "alpinejs";
import Alpine from "alpinejs";
import SquadsController from "./squads_controller";
import SquadMembersController from "./squad_members_controller";
import DebugLogEventsController from "./debug_log_events_controller";
import "sortable-tablesort/sortable.min.js";
import TogglesController from "./toggles_controller";
import TimeZonesController from "./time_zones_controller";
import FlashesController from "./flashes_controller";
import SlimController  from "./slim_controller";

window.Alpine = Alpine;
Alpine.start();

window.Stimulus = Application.start();
Stimulus.register("squad", SquadsController);
Stimulus.register("squad-member", SquadMembersController);
Stimulus.register("debug-log-events", DebugLogEventsController);
Stimulus.register("toggles", TogglesController);
Stimulus.register("time-zones", TimeZonesController);
Stimulus.register("flashes", FlashesController);
Stimulus.register("slim", SlimController);
