import { Controller } from "@hotwired/stimulus";

export default class DebugLogEventsController extends Controller<HTMLElement> {
  eventBodyTarget: HTMLElement;
  eventTogglerTextTarget: HTMLButtonElement;

  static get targets() {
    return ["eventBody", "eventTogglerText"];
  }

  toggleEventBody() {
    this.toggleAccordion({
      toggler: this.eventTogglerTextTarget,
      target: this.eventBodyTarget,
    });
  }

  private toggleAccordion({ toggler, target }) {
    const el = target;
    if (el.classList.contains("hidden")) {
      el.classList.remove("hidden");
      toggler.textContent = "Hide";
    } else {
      el.classList.add("hidden");
      toggler.textContent = "Show";
    }
  }
}
