import { Controller } from "@hotwired/stimulus";
import { debounce } from "lodash";

class SquadMembersController extends Controller<HTMLElement> {
  debouncedSaveNotes: () => void;
  formTarget: HTMLFormElement;
  nameTarget: HTMLElement;
  notesTarget: HTMLTextAreaElement;
  studentId: string;

  static get targets() {
    return ["form", "name", "notes"];
  }

  connect() {
    this.studentId = this.element.dataset.studentId;

    this.notesTarget.addEventListener("keydown", this.saveOnEnter);
    this.notesTarget.addEventListener("blur", this.saveNotes);

    this.element.addEventListener("dragstart", (e) => {
      e.dataTransfer.setData("text/plain", this.studentId);
      e.dataTransfer.setDragImage(
        this.nameTarget,
        this.nameTarget.offsetWidth * 0.5,
        this.nameTarget.offsetHeight * 0.5
      );
    });
    // Save the field if the user has stopped typing for 10 seconds
    // We don't want to do it too often, because this will unfocus the field.
    this.debouncedSaveNotes = debounce(this.saveNotes, 10_000);
  }

  saveOnEnter = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.saveNotes();
    } else {
      this.debouncedSaveNotes();
    }
  };

  saveNotes = () => {
    this.formTarget.requestSubmit();
  };
}

export default SquadMembersController;
