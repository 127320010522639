import { Controller } from "@hotwired/stimulus";

export default class TogglesController extends Controller {
  static targets = ["toggleState", "noLabel", "yesLabel"];
  static values = {
    url: String,
  };

  declare readonly urlValue: string;
  declare readonly toggleStateTarget: HTMLInputElement;
  declare readonly noLabelTarget: HTMLAnchorElement;
  declare readonly yesLabelTarget: HTMLAnchorElement;

  connect(): void {
    this.setSliderState();
  }

  setSliderState(): void {
    const isOn = this.toggleStateTarget.checked;
    const defaultClass = "px-4 rounded-lg text-zinc-800 font-semibold"
    const activeClass = `bg-white shadow border border-stone-300 ${defaultClass}`;
    const inactiveClass = `bg-zinc-100 ${defaultClass}`;
    if (isOn) {
      this.yesLabelTarget.className = activeClass;
      this.noLabelTarget.className = inactiveClass;
    } else {
      this.yesLabelTarget.className = inactiveClass;
      this.noLabelTarget.className = activeClass;
    }
  }

  async toggle() {
    await fetch(this.urlValue, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.getCSRFToken(),
      },
      body: JSON.stringify({ isChecked: this.toggleStateTarget.checked }),
    }).then(() => {
      this.setSliderState();
    });
  }

  private getCSRFToken(): string {
    const meta = document.querySelector("meta[name='csrf-token']");
    return meta?.getAttribute("content") ?? "";
  }
}
