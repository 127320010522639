import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["flash"];
  connect() {
    this.hideNotificationAfterTimeout();
  }

  hideNotificationAfterTimeout() {
    setTimeout(() => {
      this.flashTarget.style.display = "none";
    }, 3000);
  }

  get flashTarget(): HTMLElement {
    return this.targets.find("flash") as HTMLElement;
  }
}
