import { Controller } from "@hotwired/stimulus";

class SquadsController extends Controller<HTMLElement> {
  assignFormTarget: HTMLFormElement;
  deliveryId: string;
  errorsTarget: HTMLDivElement;
  rowTargets: Array<HTMLElement>;
  squadId: string;
  studentIdTarget: HTMLInputElement;

  static get targets() {
    return ["form", "studentId", "assignForm", "row", "errors"];
  }

  connect() {
    this.squadId = this.element.dataset.squadId;
    this.deliveryId = this.element.dataset.deliveryId;

    this.element.ondragover = (e) => {
      e.preventDefault();
      for (const el of this.rowTargets) {
        el.classList.add("bg-sky-50");
      }
    };

    this.element.ondrop = (e) => {
      e.preventDefault();
      for (const el of this.rowTargets) {
        el.classList.remove("bg-sky-50");
      }
      const studentId = e.dataTransfer?.getData("text/plain") || "";
      if (!Number(studentId)) {
        const errorBanner = document.createElement("div");
        ["col-span-2", "error"].forEach((c) => errorBanner.classList.add(c));
        errorBanner.innerHTML = "Whoops! That does not look like a student!";
        this.errorsTarget.appendChild(errorBanner);
        setTimeout(() => this.errorsTarget.removeChild(errorBanner), 2000);
        return;
      }
      this.studentIdTarget.value = studentId;
      this.assignFormTarget.requestSubmit();
    };

    this.element.ondragleave = () => {
      for (const el of this.rowTargets) {
        el.classList.remove("bg-sky-50");
      }
    };
  }
}

export default SquadsController;
