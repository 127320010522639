import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

export default class extends Controller {
  static values = { tz: String, options: String };
  declare readonly tzValue: string;
  declare readonly optionsValue: string;

  connect() {
    if (!this.tzValue) {
      this.updateTimeZone();
    }
  }

  async updateTimeZone() {
    try {
      const response = await fetch("/time_zones", {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }),
      });

      if (response.ok) {
        this.showSuccess("Time zone updated successfully.");
      } else {
        this.showError();
      }
    } catch (error) {
      this.showError();
    }
  }

  showSuccess(message: string) {
    this.showFlash(
      "notice",
      `<div class="flash-success my-2">${message}</div>`
    );
  }

  showError() {
    const options = JSON.parse(this.optionsValue);
    const alertHtml = `
      <div id="time-zone-alert" class="flash-alert my-2">
        <h3>Hey! 👋</h3>
        <p>It looks like you haven't set your time zone yet. We use this to make sure we're showing you the right times for your classes and workshops.</p>
        <p>Please use the dropdown below to select your time zone, and then click the button to save it.</p>
        <form action="/time_zones" method="post" data-turbo="false">
          <input type="hidden" name="_method" value="patch">
          <select name="time_zone" id="tz-selector" class="w-[22rem]">
            <option value="">Select your time zone</option>
            ${Object.entries(options)
              .map(([key, value]) => `<option value="${value}">${key}</option>`)
              .join("")}
          </select>
          <input type="submit" value="Save" class="w-[22rem] btn mt-4 py-2 px-4">
        </form>
      </div>
    `;
    this.showFlash("alert", alertHtml);

    new SlimSelect({
      select: "#tz-selector",
    });

    this.addFormSubmitHandler();
  }

  showFlash(type: string, message: string) {
    const flashContainer = document.querySelector(".flashes");

    if (flashContainer) {
      const flashMessage = document.createElement("div");
      flashMessage.innerHTML = message;

      flashContainer.appendChild(flashMessage);

      if (type === "notice") {
        setTimeout(() => {
          flashMessage.remove();
        }, 5000);
      }
    }
  }

  addFormSubmitHandler() {
    const form = document.querySelector("#time-zone-alert form");

    if (form) {
      form.addEventListener("submit", async (event: Event) => {
        event.preventDefault();

        const formData = new FormData(form as HTMLFormElement) as any;
        const jsonData = JSON.stringify(Object.fromEntries(formData.entries()));

        try {
          const response = await fetch((form as HTMLFormElement).action, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },
            body: jsonData,
          });

          if (response.ok) {
            this.removeAlert();
            this.showSuccess("Time zone updated successfully.");
          } else {
            this.showFlash(
              "error",
              "Failed to update time zone. Please try again."
            );
          }
        } catch (error) {
          this.showFlash(
            "error",
            "Failed to update time zone. Please try again."
          );
        }
      });
    }
  }

  removeAlert() {
    const alert = document.getElementById("time-zone-alert");
    if (alert) {
      alert.remove();
    }
  }
}
